<template>
  <div class="module">
    <div ref="myChart" class="my-chart-content"  style="width: 250px; height: 250px;">
      <div class="nodata">
        <span>暂无数据</span>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from 'echarts'

export default {
  name: 'EchartModule',
  props: {
    val: {
      type: Number,
      default: -1
    },
    title: {
      type: String,
      default: '(工作日)'
    }
  },
  watch: {
    val: {
      handler() {
        this.initEchart()
      }
    }
  },
  data() {
    return {
      chartA: null,
    }
  },
  methods: {
    initEchart() {
      const $el = this.$refs.myChart
      this.chartA = echarts.init($el, null, {
        locale: 'ZH'
      })
      this.chartA.showLoading()
      this.chartA.setOption({
        series: [
          {
            type: 'gauge',
            startAngle: 180,
            endAngle: 0,
            center: ['50%', '45%'],
            radius: '90%',
            min: 0,
            max: 60, // 默认100
            axisLine: { // 仪表外圈宽度
              lineStyle: {
                width: 17,
                color: [
                  [0.5, '#7CFFB2'],
                  [0.75, '#58D9F9'],
                  [0.9, '#FDDD60'],
                  [1, '#FF6E76']
                ]
              }
            },
            pointer: {
              icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
              length: '20%',
              width: 10,
              offsetCenter: [0, '-48%'],
              itemStyle: {
                color: 'auto'
              }
            },
            axisTick: {
              length: 10,
              splitNumber: 2,
              distance: 2 ,
              lineStyle: {
                color: 'auto',
                width: 2
              }
            },
            splitLine: {
              length: 10,
              lineStyle: {
                color: 'auto',
                width: 0
              }
            },
            axisLabel: {
              color: '#464646',
              fontSize: 12,
              distance: -30,
              rotate: 'tangential',
              formatter() {
                return '';
              }
            },
            detail: {
              fontSize: 32,
              fontWeight: 'normal',
              offsetCenter: [0, '-30%'],
              valueAnimation: true,
              formatter(value) {
                return value;
              },
              color: 'inherit'
            },
            title: {
              offsetCenter: [0, '-5%'],
              fontSize: 16
            },
            data: [
              {
                value: this.val,
                name: '（工作日）',
              }
            ]
          }
        ]
      })
      this.chartA.hideLoading()
    }
  }
}
</script>

<style lang="less" scoped>
</style>
