<template>
  <div class="efficiency-statistics-tab" style="margin-left: -10px;">
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="伦理审查时长" name="first">伦理审查时长</el-tab-pane>
      <el-tab-pane label="合同审查时长" name="second">合同审查时长</el-tab-pane>
      <el-tab-pane label="人遗审查时长" name="third">人遗审查时长</el-tab-pane>
      <el-tab-pane label="首例入组时长" name="fourth">首例入组时长</el-tab-pane>
      <el-tab-pane label="受试者招募完成" name="fifth">受试者招募完成</el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  name: "Tab",
  props: {
    currentName: {
      type: String,
      default: ''
    }
  },
  watch: {
    activeName: {
      handler() {
        this.activeName = this.currentName
      },
      immediate: true
    }
  },
  data() {
    return {
      activeName: ""
    }
  },
  methods: {
    handleClick(tab, event) {
      const { activeName } = this
      const basePath = "/report/efficiency-statistics/"
      if (activeName === "first") {
        this.$router.push({ path: `${basePath}ethical-review` })
      } else if (activeName === "second") {
        this.$router.push({ path: `${basePath}contract-review` })
      } else if (activeName === "third") {
        this.$router.push({ path: `${basePath}human-heritage-review` })
      } else if (activeName === "fourth") {
        this.$router.push({ path: `${basePath}first-enrollment-duration` })
      } else if (activeName === "fifth") {
        this.$router.push({ path: `${basePath}recruitment-duration` })
      }
    },
  }
}
</script>

<style scoped>
.efficiency-statistics-tab /deep/ .el-tabs .el-tabs__nav{
  border: none;
}
.efficiency-statistics-tab /deep/ .el-tabs .el-tabs__item {
  height: 33px;
  line-height: 33px;
  width: 144px;
  font-size: 14px;
  text-align: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border:1px solid #0a84ff;
  margin-right: -1px;
}
.efficiency-statistics-tab /deep/ .el-tabs .is-active,
.efficiency-statistics-tab /deep/ .el-tabs .el-tabs__item:hover{
  color: #fff;
  background: #2d8cf0;
}
</style>
